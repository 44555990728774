module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LZ8602BHP1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Titillium Web","Montserrat"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cannon Lock's Personal Website","short_name":"Lock Dot Com","start_url":"/index.js","display":"minimal-ui","icon":"src/static/images/WebsiteFavicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0aff92e58142e290bc30a4efc68fbef2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/Personal-Website/Personal-Website/src/components/layout/MD_Layout.js"},"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/Personal-Website/Personal-Website/node_modules/gatsby-remark-images","id":"6427aa54-580d-5b02-a39f-b1b1ff711307","name":"gatsby-remark-images","version":"6.12.1","modulePath":"/home/runner/work/Personal-Website/Personal-Website/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"backgroundColor":"transparent"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/Personal-Website/Personal-Website","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"backgroundColor":"transparent"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
