exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-ai-birdwatching-mdx": () => import("./../../../src/pages/blog/AI_Birdwatching.mdx" /* webpackChunkName: "component---src-pages-blog-ai-birdwatching-mdx" */),
  "component---src-pages-blog-benchmarking-network-with-python-mdx": () => import("./../../../src/pages/blog/Benchmarking_Network_With_Python.mdx" /* webpackChunkName: "component---src-pages-blog-benchmarking-network-with-python-mdx" */),
  "component---src-pages-blog-gatsby-deploy-gh-action-mdx": () => import("./../../../src/pages/blog/Gatsby_Deploy_GH_Action.mdx" /* webpackChunkName: "component---src-pages-blog-gatsby-deploy-gh-action-mdx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-transit-visualization-mdx": () => import("./../../../src/pages/blog/Transit_Visualization.mdx" /* webpackChunkName: "component---src-pages-blog-transit-visualization-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-charter-network-benchmark-mdx": () => import("./../../../src/pages/projects/Charter_Network_Benchmark.mdx" /* webpackChunkName: "component---src-pages-projects-charter-network-benchmark-mdx" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-three-js-dancefloor-mdx": () => import("./../../../src/pages/projects/Three_JS_Dancefloor.mdx" /* webpackChunkName: "component---src-pages-projects-three-js-dancefloor-mdx" */),
  "component---src-pages-threejs-jsx": () => import("./../../../src/pages/threejs.jsx" /* webpackChunkName: "component---src-pages-threejs-jsx" */)
}

